import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';

import React from 'react';
import { GlobalStyles } from 'styles/stitches.config';
import { ChakraProvider } from '@chakra-ui/react';
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from '@tanstack/react-query';

import { extendTheme } from '@chakra-ui/react';
import NextNProgress from 'nextjs-progressbar';

export const theme = extendTheme({
  config: {
    cssVarPrefix: 'ck',
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  GlobalStyles();

  const queryClient = React.useRef(new QueryClient());

  const removeGrassPath = (path: string) => {
    if(!path.includes('/gramas')) return path;
    return path.replace('/gramas', '');
  }

  const canonicalUrl = removeGrassPath(`https://centraldagrama.com${router.asPath}`);

  return (
    <>
      <Head>
        <link  rel="canonical" href={canonicalUrl} />
        <link  rel="max-snippet" href={canonicalUrl} />
      </Head>
      <QueryClientProvider client={queryClient.current}>
        <Hydrate state={pageProps.dehydrateState}>
          <ChakraProvider theme={theme}>
            <NextNProgress color="#10b981" />
            <Component {...pageProps} />
          </ChakraProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
