import { createStitches } from '@stitches/react'
import { rem } from 'polished'

export const { styled, getCssText, globalCss, css, theme, keyframes } = createStitches({
  prefix: 'cg',

  media: {
    maxmd: '(max-width: 768px)',
    sm: `(min-width: 576px)`,
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1400px)',
  },
  theme: {
    fonts: {
      domine: 'Domine',
      raleway: 'Raleway',
      manrope: 'Manrope',
    },
    colors: {
      emerald50: '#ecfdf5',
      emerald100: '#d1fae5',
      emerald200: '#a7f3d0',
      emerald300: '#6ee7b7',
      emerald400: '#34d399',
      emerald500: '#095D3F',
      emerald600: '#059669',
      emerald700: '#047857',
      emerald800: '#065f46',
      emerald900: '#064e3b',
      grey50: '#f9fafb',
      grey100: '#f3f4f6',
      grey200: '#e5e7eb',
      grey300: '#d1d5db',
      grey400: '#9ca3af',
      grey500: '#6b7280',
      grey600: '#4b5563',
      grey700: '#374151',
      grey800: '#1f2937',
      grey900: '#111827',
      rose50: '#fff1f2',
      rose100: '#ffe4e6',
      rose200: '#fecdd3',
      rose300: '#fda4af',
      rose400: '#fb7185',
      rose500: '#f43f5e',
      rose600: '#e11d48',
      rose700: '#be123c',
      rose800: '#9f1239',
      rose900: '#881337',
      blue50: '#eff6ff',
      blue100: '#dbeafe',
      blue200: '#bfdbfe',
      blue300: '#93c5fd',
      blue400: '#60a5fa',
      blue500: '#3b82f6',
      blue600: '#2563eb',
      blue700: '#1d4ed8',
      blue800: '#1e40af',
      blue900: '#1e3a8a',
      orange: '#F2994A',
      yellow: '#F2C94C',
      purple1: '#9B51E0',
      purple2: '#BB6BD9',
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  utils: {
    // Abbreviated margin properties
    m: (value: any) => ({
      margin: value,
    }),
    mt: (value: any) => ({
      marginTop: value,
    }),
    mr: (value: any) => ({
      marginRight: value,
    }),
    mb: (value: any) => ({
      marginBottom: value,
    }),
    ml: (value: any) => ({
      marginLeft: value,
    }),
    mx: (value: any) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: any) => ({
      marginTop: value,
      marginBottom: value,
    }),
  },
})

export const GlobalStyles = globalCss({
  '*': { margin: 0, padding: 0, boxSizing: 'border-box' },

  html: {
    fontSize: 19,
    scrollBehavior: 'smooth',
  },

  'h1,h2,h3,h4,h5,h6': {
    fontFamily: '$domine',
    fontWeight: 'bold !important',
    lineHeight: '120%',
  },

  h1: {
    fontSize: `${rem(60)} !important`,
  },

  h2: {
    fontSize: `${rem(20)} !important`,
    '@md': {
      fontSize: `${rem(35)} !important`,
    },
    '@xxl': {
      fontSize: `${rem(43)} !important`,
    },
  },

  h3: {
    fontSize: `${rem(27)} !important`,
  },
  h4: {
    fontSize: `${rem(17)} !important`,
  },
  h5: {
    fontSize: `${rem(17)} !important`,
  },

  'input,select,textarea': {
    color: '$grey600 !important',
    fontFamily: '$raleway !important',
    fontSize: '13px !important',
  },

  '.chakra-form__error-message': {
    fontFamily: '$raleway !important',
    fontSize: '13px !important',
  },
})
